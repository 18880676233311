// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
Rails.start();

require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");
require("data-confirm-modal");
require("trix");
require("@rails/actiontext");
require("selectize");
require("chartkick");
require("chart.js");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "bootstrap/dist/js/bootstrap";
import "datatables.net";
import "datatables.net-bs4";
import "datatables.net-dt";
import "heatmap.js";
import L from "leaflet";
import HeatmapOverlay from "leaflet-heatmap";
import Sortable from "sortablejs";
import "../../assets/stylesheets/application";

document.addEventListener("turbolinks:load", () => {
  // Heatmap for dashboard
  if (document.querySelector("#map")) {
    const cfg = {
      radius: 0.1,
      maxOpacity: 0.8,
      scaleRadius: true,
      useLocalExtrema: false,
      latField: "lat",
      lngField: "lng",
      valueField: "count",
    };

    let heatmapLayer = new HeatmapOverlay(cfg);

    let map = L.map("map").setView([56.520629, -4.02051], 6);
    L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      subdomains: ["a", "b", "c"],
    }).addTo(map);

    map.createPane("heatPane");
    heatmapLayer = new HeatmapOverlay(cfg).addTo(map);
    heatmapLayer["overlayPane"] = "heatPane";
    heatmapLayer.setData({ max: mapData.length, data: mapData });
  }

  if (document.querySelector("#alertsMap")) {
    const cfg = {
      radius: 0.1,
      maxOpacity: 0.8,
      scaleRadius: true,
      useLocalExtrema: false,
      latField: "lat",
      lngField: "lng",
      valueField: "count",
    };

    let heatmapLayer = new HeatmapOverlay(cfg);

    let map = L.map("alertsMap").setView([56.520629, -4.02051], 6);
    L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      subdomains: ["a", "b", "c"],
    }).addTo(map);

    map.createPane("heatPane");
    heatmapLayer = new HeatmapOverlay(cfg).addTo(map);
    heatmapLayer["overlayPane"] = "heatPane";
    heatmapLayer.setData({ max: alertsData.length, data: alertsData });
  }

  if (document.querySelector("#alertMap")) {
    let map = L.map("alertMap").setView([lostLat, lostLng], 14);
    L.tileLayer("http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
      subdomains: ["a", "b", "c"],
    }).addTo(map);

    var lost = L.circle([lostLat, lostLng], {
      color: "red",
      fillColor: "#f03",
      fillOpacity: 0.5,
      radius: 500,
    }).addTo(map);

    lost.bindPopup("Went missing here");

    if (foundLat && foundLng) {
      var found = L.circle([foundLat, foundLng], {
        color: "green",
        fillColor: "#00EA95",
        fillOpacity: 0.5,
        radius: 500,
      }).addTo(map);
      found.bindPopup("Found here");
    }
  }

  // Setup datatables
  const dataTable = $(".data-table")[0];
  if (dataTable != null && dataTable.dataset.configured !== "true") {
    dataTable.dataset.configured = true;
    $(dataTable).DataTable({
      responsive: true,
      pagingType: "full",
      dom: '<"top"f>rt<"bottom"lip><"clear">',
      language: { search: "", searchPlaceholder: "Search" },
    });
  }

  Chartkick.configure({ language: "en", mapsApiKey: "..." });

  // Setup select inputs (category name search)
  $(".selectize").selectize({
    create: true,
    createOnBlur: true,
  });

  // Setup drag and drop reordering (category#show posts)
  if (document.querySelector(".sortable")) {
    let messageClearTimeout = null;
    Sortable.create($(".sortable")[0], {
      animation: 150,
      ghostClass: "blue-background-class",
      onEnd: (e) => {
        if (messageClearTimeout != null) {
          clearTimeout(messageClearTimeout);
        }

        // Update position values
        const inputs = $(".sortable .position").toArray();
        for (let index in inputs) {
          const input = inputs[index];
          input.value = index;
        }

        // Submit
        const form = $(e.target).parent("form")[0];
        Rails.fire(form, "submit");
      },
    });
  }
});

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});
